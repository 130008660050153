<template>
    <v-container fluid style="padding-bottom:80px">
        <v-row>
            <v-col cols="12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </v-col>
        </v-row>

        <v-row>
            <v-snackbar v-model="snackbar.visible" :color="snackbar.color" :multi-line="snackbar.mode === 'multi-line'" :timeout="snackbar.timeout" :top="snackbar.position === 'top'">
                <v-layout align-center pr-4>
                <v-icon class="pr-3" dark large>{{ snackbar.icon }}</v-icon>
                <v-layout column>
                    <div>
                    <strong>{{ snackbar.title }}</strong>
                    </div>
                    <div>{{ snackbar.text }}</div>
                </v-layout>
                </v-layout>
                <v-btn v-if="snackbar.timeout === 0" icon @click="snackbar.visible = false">
                <v-icon>clear</v-icon>
                </v-btn>
            </v-snackbar>
            <v-col cols="12">
                <v-card outlined class="p-0">
                    <v-container fluid>
                        <v-row>
                            <v-col class="col-6" sm="6" md="2">
                                <v-menu
                                    ref="modal"
                                    v-model="modal"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        dense
                                        v-model="start_period"
                                        label="Start Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ma-0 pa-1 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="start_period"
                                        no-title
                                        type="month"
                                        @input="modal = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-menu
                                    ref="menu"
                                    v-model="menu"
                                    :close-on-content-click="false"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="auto"
                                    >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                        solo
                                        v-model="end_period"
                                        label="End Period"
                                        persistent-hint
                                        prepend-inner-icon="mdi-calendar"
                                        v-bind="attrs"
                                        v-on="on"
                                        dense
                                        class="ma-0 pa-1 border-12"
                                        hide-details=true
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="end_period"
                                        no-title
                                        type="month"
                                        @input="menu = false"
                                    ></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-ev-plug-type1"
                                    clearable
                                    solo
                                    dense
                                    v-model="machine_type"
                                    :items="machine_types"
                                    item-value="mach_type"
                                    item-text="descr"
                                    label="Machine Type"
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                    @change="(event) => [getMachine(event)]"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-slot-machine-outline"
                                    clearable
                                    solo
                                    dense
                                    v-model="machine"
                                    :items="machines"
                                    item-value="mach_id"
                                    item-text="descr"
                                    label="Machine"
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                    :loading="loading_machine"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-watermark"
                                    clearable
                                    solo
                                    v-model="brand"
                                    :items="brands"
                                    item-value="brand_id"
                                    item-text="descr"
                                    label="Brand"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                    @change="(event) => getColour(event)"
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-coat-rack"
                                    clearable
                                    solo
                                    v-model="coat_mass"
                                    :items="coat_masss"
                                    item-value="coat_mass"
                                    :item-text="item => 'AS' + item.coat_mass"
                                    label="Coat (AS - AZ)"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-star-circle"
                                    clearable
                                    solo
                                    v-model="grade"
                                    :items="grades"
                                    item-value="grade_id"
                                    item-text="grade_id"
                                    label="Grade"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-up-down"
                                    clearable
                                    solo
                                    v-model="thick"
                                    :items="thickness"
                                    item-value="thick"
                                    :item-text="item => $store.getters.convertToCurrency(item.thick)"
                                    label="Thickness"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                                
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-arrow-left-right"
                                    clearable
                                    solo
                                    v-model="width"
                                    :items="widths"
                                    item-value="width"
                                    :item-text="item => $store.getters.convertToCurrency(item.width)"
                                    label="Width"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-palette"
                                    clearable
                                    solo
                                    v-model="colour"
                                    :items="colours"
                                    item-value="color_id"
                                    item-text="descr"
                                    label="Colour"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                            <v-col class="col-6" sm="6" md="2">
                                <v-autocomplete
                                    prepend-inner-icon="mdi-quality-high"
                                    clearable
                                    solo
                                    v-model="quality"
                                    :items="qualities"
                                    item-value="quality_id"
                                    item-text="quality_id"
                                    label="Quality"
                                    dense
                                    class="ma-0 pa-1 border-12"
                                    hide-details=true
                                ></v-autocomplete>
                            </v-col>
                             <v-col class="col-6" sm="6" md="2">
                                <v-btn class="mt-1 border-12" style="padding: 19px;" color="info" elevation="2" @click="submit()"><v-icon class="mr-1">mdi-magnify</v-icon> Search</v-btn>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card>
            </v-col>
        </v-row>

        <v-row class="mt-5">
            <v-col cols="12">
                <v-tabs
                    v-model="tab"
                    icons-and-text
                    class="round_top col-sm-no-padding ml-6"
                    show-arrows
                >
                    <v-tabs-slider color="primary"></v-tabs-slider>

                    <v-tab
                        v-for="(tab, index) in tabs"
                        :key="index"
                        :href="'#tab-'+tab.int1"
                        active-class="active-tab"
                    >
                        {{ tab.str1 }}
                    </v-tab>

                </v-tabs>
                <v-tabs-items v-model="tab">
                    <v-tab-item
                        value="tab-1"
                    >
                        <v-card class="ma-0 pb-4 round_top_29" height="auto" outlined>
                            <v-container fluid class="p-3">
                                <v-row>
                                    <v-col cols="12">
                                        <v-card id="sumScShipmentAnalysis" outlined height="430">
                                            <v-card-text>
                                                <div class="d-flex w-100 ma-0 mb-2">
                                                    <h6 class="indigo-text m-0">Query Result: </h6>
                                                    <v-spacer />
                                                    <v-btn text color="error" class="mr-1">
                                                    Total Defect : {{$store.getters.convertToCurrencyUs(total)}} TON
                                                    </v-btn>
                                                </div>
                                                <v-img
                                                class="text-center mt-5 mx-auto"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="300"
                                                src="@/assets/empty_data.png"
                                                v-if="showData"
                                                ></v-img>
                                                <div id="container1" style="height: 370px; width: 100%;"></div>
                                            </v-card-text>
                                        </v-card>
                                    </v-col>
                                </v-row>
                                <v-row class="mt-5">
                                    <v-col cols="12" xs="12" 
                                    sm="6" 
                                    md="4">
                                        <v-card outlined height="400">
                                            <v-card-text>
                                                <v-img
                                                class="text-center mx-auto"
                                                style="margin-top: 80px;"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="200"
                                                src="@/assets/empty_data.png"
                                                v-if="showDataDetail"
                                                ></v-img>
                                                <div id="container2" style="height: 370px; width: 100%;"></div>
                                            </v-card-text> 
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" xs="12" 
                                    sm="6" 
                                    md="4">
                                        <v-card outlined height="400">
                                            <v-card-text>
                                                <v-img
                                                class="text-center mx-auto"
                                                style="margin-top: 80px;"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="200"
                                                src="@/assets/empty_data.png"
                                                v-if="showDataDetail"
                                                ></v-img>
                                                <div id="container3" style="height: 370px; width: 100%;"></div>
                                            </v-card-text> 
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" xs="12" 
                                    sm="6" 
                                    md="4">
                                        <v-card outlined height="400">
                                            <v-card-text>
                                                <v-img
                                                class="text-center mx-auto"
                                                style="margin-top: 80px;"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="200"
                                                src="@/assets/empty_data.png"
                                                v-if="showDataDetail"
                                                ></v-img>
                                                <div id="container4" style="height: 370px; width: 100%;"></div>
                                            </v-card-text> 
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" xs="12" 
                                    sm="6" 
                                    md="4">
                                        <v-card outlined height="400">
                                            <v-card-text>
                                                <v-img
                                                class="text-center mx-auto"
                                                style="margin-top: 80px;"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="200"
                                                src="@/assets/empty_data.png"
                                                v-if="showDataDetail"
                                                ></v-img>
                                                <div id="container5" style="height: 370px; width: 100%;"></div>
                                            </v-card-text> 
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" xs="12" 
                                    sm="6" 
                                    md="4">
                                        <v-card outlined height="400">
                                            <v-card-text>
                                                <v-img
                                                class="text-center mx-auto"
                                                style="margin-top: 80px;"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="200"
                                                src="@/assets/empty_data.png"
                                                v-if="showDataDetail"
                                                ></v-img>
                                                <div id="container6" style="height: 370px; width: 100%;"></div>
                                            </v-card-text> 
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" xs="12" 
                                    sm="6" 
                                    md="4">
                                        <v-card outlined height="400">
                                            <v-card-text>
                                                <v-img
                                                class="text-center mx-auto"
                                                style="margin-top: 80px;"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="200"
                                                src="@/assets/empty_data.png"
                                                v-if="showDataDetail"
                                                ></v-img>
                                                <div id="container7" style="height: 370px; width: 100%;"></div>
                                            </v-card-text> 
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" xs="12" 
                                    sm="6" 
                                    md="4">
                                        <v-card outlined height="400">
                                            <v-card-text>
                                                <v-img
                                                class="text-center mx-auto"
                                                style="margin-top: 80px;"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="200"
                                                src="@/assets/empty_data.png"
                                                v-if="showDataDetail"
                                                ></v-img>
                                                <div id="container8" style="height: 370px; width: 100%;"></div>
                                            </v-card-text> 
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" xs="12" 
                                    sm="6" 
                                    md="4">
                                        <v-card outlined height="400">
                                            <v-card-text>
                                                <v-img
                                                class="text-center mx-auto"
                                                style="margin-top: 80px;"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="200"
                                                src="@/assets/empty_data.png"
                                                v-if="showDataDetail"
                                                ></v-img>
                                                <div id="container9" style="height: 370px; width: 100%;"></div>
                                            </v-card-text> 
                                        </v-card>
                                    </v-col>
                                    <v-col cols="12" xs="12" 
                                    sm="6" 
                                    md="4">
                                        <v-card outlined height="400">
                                            <v-card-text>
                                                <v-img
                                                class="text-center mx-auto"
                                                style="margin-top: 80px;"
                                                lazy-src="@/assets/empty_data.png"
                                                max-width="200"
                                                src="@/assets/empty_data.png"
                                                v-if="showDataDetail"
                                                ></v-img>
                                                <div id="container10" style="height: 370px; width: 100%;"></div>
                                            </v-card-text> 
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item value="tab-2">
                        <v-card class="ma-0 pb-4 round_top_29" height="auto" outlined>
                            <v-card-text class="pb-12">
                                <div class="d-flex w-100 ma-0 mb-2">
                                    <h6 class="indigo-text m-0">Query Result: </h6>
                                    <v-spacer />
                                    <v-btn text color="error" class="mr-1">
                                    Total Defect : {{$store.getters.convertToCurrencyUs(total)}} TON
                                    </v-btn>
                                </div>
                                <v-sheet
                                    class="mx-auto"
                                >
                                    <v-slide-group
                                    multiple
                                    show-arrows
                                    >
                                        <v-slide-item class="mx-1"  v-for="(defect, index) in dt_defects" :key="index">
                                            <v-card class="rounded-l" outlined max-width="180">
                                                <v-card-text class="pl-4 pr-4">
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <div class="d-flex">
                                                                {{defect.periode}} - {{defect.group_def.trim()}}
                                                                <v-spacer></v-spacer>
                                                                <v-checkbox
                                                                class="ma-0 pa-0 border-12"
                                                                hide-details=true
                                                                dense
                                                                v-model="defect.check"
                                                                @change="getDetailDt(defect, index)"
                                                                ></v-checkbox>
                                                            </div>
                                                        </v-col>
                                                        <v-col cols="12" class="mt-3 text-center">
                                                            <h6 style="font-weight:600;text-align: center;" class="mt-0 pt-0">
                                                                {{$store.getters.convertToCurrencyUs(defect.total_wgt)}} TON
                                                            </h6>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </v-slide-item>
                                    </v-slide-group>
                                </v-sheet>
                                <v-data-table dense :headers="headers" :items="items" class="elevation-1 mt-3" :items-per-page="30"
                                    height="450" fixed-header :divider="true" :light="true" :loading="$store.state.overlay" :search="search">
                                    <template v-slot:top>
                                        <v-toolbar flat color="white" class="mt-2 mb-2">
                                            <div class="d-flex w-100">
                                                <v-spacer></v-spacer>
                                                <v-text-field solo style="max-width: 300px;" class="border-12 mr-3" v-model="search"
                                                    append-icon="mdi-magnify" label="Search" dense single-line
                                                    hide-details></v-text-field>
                                            </div>
                                        </v-toolbar>
                                    </template>
                                    <template v-slot:[`item.wgt`]="{ item }">
                                        {{ item.wgt ? $store.getters.convertToCurrencyUs(item.wgt / 1000) : 0 }}
                                    </template>
                                    <template v-slot:[`item.thick`]="{ item }">
                                        {{ item.thick ? $store.getters.convertToCurrencyUs(item.thick) : 0 }}
                                    </template>
                                    <template v-slot:[`item.width`]="{ item }">
                                        {{ item.width ? $store.getters.convertToCurrencyUs(item.width) : 0 }}
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import {backendApi} from "@/backend-api/backend-api-sr"

export default {
    data() {
        return {
            detail: false,
            start_period: '',
            end_period: '',
            menu: false,
            modal: false,
            loading: false,
            brands: [],
            brand: '',
            coat_masss: [],
            coat_mass: '',
            grades: [],
            grade: '',
            thickness: [],
            thick: '',
            widths: [],
            width: '',
            colours: [],
            colour: '',
            qualities: [],
            quality: '',
            snackbar: {
                color: null,
                icon: null,
                mode: null,
                position: "top",
                text: null,
                timeout: 7500,
                title: null,
                visible: false
            },
            breadcumbs: [
                {
                text: 'Sunrise Steel',
                disabled: false,
                href: '/admin/sr',
                },
                {
                text: 'Mill Data Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
                {
                text: 'Defect Analysis',
                disabled: true,
                href: 'breadcrumbs_link_2',
                },
            ],
            machines: [],
            machine: '',
            x:  window.matchMedia("(max-width: 991px)"),
            showData: true,
            showDataDetail: true,
            tab: 'tab-1',
            tabs: [
                { int1: 1, str1: 'Chart' },
                { int1: 2, str1: 'DataTable' }
            ],
            headers: [
                { text: 'Group Defect', value: 'group_def', align: 'left'},
                { text: 'TR ID', value: 'tr_id', align: 'left', groupable: false},
                { text: 'Period', value: 'periode', align: 'left', groupable: false},
                { text: 'Date Prod', value: 'dt_prod', align: 'left', groupable: false},
                { text: 'Shift', value: 'shift_num', align: 'right', groupable: false},
                { text: 'Product', value: 'descr', align: 'left', groupable: false},
                { text: 'Defect', value: 'defect', align: 'left', groupable: false},
                { text: 'Wgt', value: 'wgt', align: 'right', groupable: false},
                { text: 'Mach Type', value: 'mach_type', align: 'left', groupable: false},
                { text: 'Mach ID', value: 'mach_id', align: 'left', groupable: false},
                { text: 'Coil ID', value: 'coil_id', align: 'left', groupable: false},
                { text: 'Brand ID', value: 'brand_id', align: 'left', groupable: false},
                { text: 'Thick', value: 'thick', align: 'right', groupable: false},
                { text: 'Width', value: 'width', align: 'right', groupable: false},
                { text: 'Grade', value: 'grade_id', align: 'left', groupable: false},
                { text: 'Coat Mass', value: 'coat_mass', align: 'left', groupable: false},
                { text: 'Quality ID', value: 'quality_id', align: 'left', groupable: false},
                { text: 'Color ID', value: 'color_id', align: 'left', groupable: false},
                { text: 'Origin ID', value: 'origin_id', align: 'left', groupable: false},
                { text: 'Pita', value: 'pita', align: 'right', groupable: false},
            ],
            items: [],
            search: '',
            headersColumn:{

            },
            machine_types: [],
            machine_type: '',
            loading_machine: false,
            dt_defects: [],
            dt_defect: null,
            checkbox: false
        }
    },
    async mounted(){
        this.$store.dispatch('setOverlay', true)
        await this.getMachineType()
        await this.getBrand()
        await this.getCoatMass()
        await this.getGrade()
        await this.getQuality()
        this.$store.dispatch('setOverlay', false)

        await this.getThick()
        await this.getWidth()

    },
    computed:{
        total(){
            var total = 0
            for (let index = 0; index < this.dt_defects.length; index++) {
                total = total + Number(this.dt_defects[index].total_wgt);
            }
            
            return (total | 0)
        },
    },
    methods:{
        async getMachineType(){
            await axios.get(`${process.env.VUE_APP_URL}/v2/master/sr/mach_type`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.machine_types = res.data
            });
        },
        async getMachine(event){
            if(event == '' || event == null){
                this.machine_type = ''
            } else {
                this.loading_machine = true
                await axios.get(`${process.env.VUE_APP_URL}/v2/master/sr/mach_id?mach_type=${this.machine_type ? this.machine_type : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.machines = res.data
                    this.loading_machine = false
                });
            }

        },
        async getBrand(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/brand`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.brands = res.data
            });
        },
        async getCoatMass(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/coat_mass`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.coat_masss = res.data
            });
        },
        async getGrade(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/grade`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.grades = res.data
            });
        },
        async getColour(event){
            if(event == '' || event == null){
                this.brand = ''
            } else {
                await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/colour?brand=${this.brand ? this.brand : ''}`, { 
                    headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
                })
                .then(res => {
                    this.colours = res.data
                });
            }
        },
        async getThick(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/thicks`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.thickness = res.data
            });
        },
        async getWidth(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/width`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.widths = res.data
            });
        },
        async getQuality(){
            await axios.get(`${process.env.VUE_APP_URL}/api/master/sr/quality`, { 
                headers: { Authorization: `Bearer ${this.$store.getters.isTokenUser}` } 
            })
            .then(res => {
                this.qualities = res.data
            });
        },
        async submit(){
            this.$store.dispatch('setOverlay', true)

            var start = this.start_period ? new Date(this.start_period).toISOString().substr(0, 7).replace(/-/g,"") : ''
            var end = this.end_period ? new Date(this.end_period).toISOString().substr(0, 7).replace(/-/g,"") : ''

            var reqBody = {
                'start': start,
                'end': end,
                'mach_type': this.machine_type ? this.machine_type : '',
                'mach_id': this.machine ? this.machine : '',
                'brand_id': this.brand ? this.brand : '',
                'coat_mass': this.coat_mass ? this.coat_mass : '',
                'grade_id': this.grade ? this.grade : '',
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'color_id': this.colour ? this.colour : '',
                'quality_id': this.quality ? this.quality : ''
            }
            // const respData = await backendApi.fetchCore('/v2/sr/analysis/defect', reqBody, false, false, false)
            const respDataChart = await backendApi.fetchCore('/v2/sr/analysis/defect/chart', reqBody, false, false, false)

            if (respDataChart.status === 200) {
                var container = "container1";
                this.dt_defects = []
                this.getPieChart([], 'container2', '', '', true)
                this.getPieChart([], 'container3', '', '', true)
                this.getPieChart([], 'container4', '', '', true)
                this.getPieChart([], 'container5', '', '', true)
                this.getPieChart([], 'container6', '', '', true)
                this.getPieChart([], 'container7', '', '', true)
                this.getPieChart([], 'container8', '', '', true)
                this.getPieChart([], 'container9', '', '', true)
                this.getPieChart([], 'container10', '', '', true)
                this.showDataDetail = true

                for (let index = 0; index < respDataChart.data.length; index++) {
                    const element = respDataChart.data[index];
                    this.dt_defects.push({
                        periode:element.periode,
                        group_def:element.group_def,
                        total_wgt:element.total_wgt,
                        check: false
                    })                   
                }

                var inputJson = respDataChart.data
                const transformedData = {};

                inputJson.forEach(item => {
                    const group_def = item.group_def.trim() == "" ? 'empty' : item.group_def.trim().replace(/ /g,"").replace(/\//g,"").toLowerCase();
                    if (!transformedData[group_def]) {
                        transformedData[group_def] = [];
                    }
                    console.log(item.periode.substr(0, 4), item.periode.substr(4, 6));

                    transformedData[group_def].push({
                        x: new Date(item.periode.substr(0, 4) + '-' + item.periode.substr(4, 6)),
                        y: parseFloat(item.total_wgt),
                        group_def: item.group_def,
                        periode: item.periode
                    });
                });
                console.log(transformedData);

                var crc = (transformedData.crc) ? transformedData.crc : []
                var equipment = (transformedData.equipment) ? transformedData.equipment : []
                var handling = (transformedData.handling) ? transformedData.handling : []
                var mainissues = (transformedData.mainissues) ? transformedData.mainissues : []
                var process = (transformedData.process) ? transformedData.process : []
                var processcrc = (transformedData.processcrc) ? transformedData.processcrc : []
                var processequipment = (transformedData.processequipment) ? transformedData.processequipment : []

                // this.items = respData.data

                await this.getChart(crc, equipment, handling, mainissues, process, processcrc,processequipment, "CRC", "EQUIPMENT", "HANDLING", "MAIN ISSUES", "PROCESS", "PROCESS/CRC", "PROCESS/EQUIPMENT",container, 'column');

                $('html, body').animate({
                    scrollTop: $("#sumScShipmentAnalysis").offset().top
                }, 1200)

                this.showData = false

                this.$store.dispatch('setOverlay', false)    
            } else {
                this.$store.dispatch('setOverlay', false)    
            }
        },
        getChart(dt1, dt2, dt3, dt4, dt5, dt6, dt7, name1, name2, name3, name4, name5, name6, name7, container, type){
            var chart = new CanvasJS.Chart(container, {
                animationEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: 'Defect Analysis Summary',
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: 'Weight',
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                axisX: {
                    // labelFormatter: function (e) {
                    //     console.log(new Date(e.value - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 7));
                    //     // var month = e.value.substr(0, 4) + '-' + e.value.substr(5, 7)
                    //     // console.log(month);
                    //     return (new Date(e.value - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7);
                    // },
                    interval: 1,
                    intervalType: "month"
                },
                axisY: {
                    // scaleBreaks: {
                    //     autoCalculate: true,
                    //     type: "wavy"
                    // },
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "TON",
                    labelFormatter: this.addSymbols,
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    // cursor: "pointer",
                    itemclick: this.toggleDataSeries,
                    fontSize: 11
                },
                data: [
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name1,
                        // indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        // indexLabelPlacement: "outside",  
                        // indexLabelOrientation: "horizontal",
                        // color: "#73ab4a",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name2,
                        // indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        // indexLabelPlacement: "outside",  
                        // indexLabelOrientation: "horizontal",
                        // color: "#ee7c32",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name3,
                        // indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        // indexLabelPlacement: "outside",  
                        // indexLabelOrientation: "horizontal",
                        // color: "#009688",
                        // yValueFormatString: "#,###,,,.##",
                    }, 
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name4,
                        // indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        // indexLabelPlacement: "outside",  
                        // indexLabelOrientation: "horizontal",
                        // color: "#FF9800",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name5,
                        // indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        // indexLabelPlacement: "outside",  
                        // indexLabelOrientation: "horizontal",
                        // color: "#795548",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name6,
                        // indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        // indexLabelPlacement: "outside",  
                        // indexLabelOrientation: "horizontal",
                        // color: "#1565C0",
                        // yValueFormatString: "#,###,,,.##",
                    },
                    {
                        type: "column",
                        click: this.showDetail,
                        name: name7,
                        // indexLabel: "{y}",
                        indexLabelFontSize: 14,
                        // indexLabelFontColor: "#FFF",
                        showInLegend: true,
                        // indexLabelPlacement: "outside",  
                        // indexLabelOrientation: "horizontal",
                        // color: "#7f7f7f",
                        // yValueFormatString: "#,###,,,.##",
                    }
                ]
            });

            chart.options.data[0].dataPoints = dt1;
            chart.options.data[1].dataPoints = dt2;
            chart.options.data[2].dataPoints = dt3;
            chart.options.data[3].dataPoints = dt4;
            chart.options.data[4].dataPoints = dt5;
            chart.options.data[5].dataPoints = dt6;
            chart.options.data[6].dataPoints = dt7;


            // this.showDefaultText(chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");
            if (x.matches) {

                for(var i = 0; i < chart.options.data.length; i++){
                    chart.options.data[i].indexLabelFontSize = 8;
                }
                chart.render();
            }
            chart.render();
        },
        getPieChart(dt_detail, container_detail, title_detail, label_detail, destroy = false){
            var pie_chart = new CanvasJS.Chart(container_detail, {
                animationEnabled: true,
                exportEnabled: true,
                theme: "light2",
                exportEnabled: true,
                title: {
                    text: title_detail,
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: true,
                subtitles:[
                    {
                        text: label_detail,
                        //Uncomment properties below to see how they behave
                        fontFamily: "Calibri",
                        fontColor: "red",
                        fontSize: 12
                    }
                ],
                legend: {
                    itemclick: this.toggleDataPointVisibility
                },
                data: [{

                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 12,

                }]
            });

            pie_chart.options.data[0].dataPoints = dt_detail;
            // this.showDefaultText(pie_chart, "No Data Found!");
            var x = window.matchMedia("(max-width: 991px)");

            if (x.matches) {

                for(var i = 0; i < pie_chart.options.data.length; i++){
                    pie_chart.options.data[i].indexLabelFontSize = 6;
                }
                pie_chart.render();
            }
            pie_chart.render();

            if (destroy == true) {
                pie_chart.destroy();
            }
        },
        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },
        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },
        toggleDataPointVisibility(e) {
            if(e.dataPoint.hasOwnProperty("actualYValue") && e.dataPoint.actualYValue !== null) {
                e.dataPoint.y = e.dataPoint.actualYValue;
                e.dataPoint.actualYValue = null;
                e.dataPoint.indexLabelFontSize = null;
                e.dataPoint.indexLabelLineThickness = null;
                e.dataPoint.legendMarkerType = "circle";
            } 
            else {
                e.dataPoint.actualYValue = e.dataPoint.y;
                e.dataPoint.y = 0;
                e.dataPoint.indexLabelFontSize = 0;
                e.dataPoint.indexLabelLineThickness = 0; 
                e.dataPoint.legendMarkerType = "cross";
            }
                e.chart.render();
        },
        showDefaultText(chart, text) {
            var dataPoints = chart.options.data[0].dataPoints;
            var isEmpty = !(dataPoints && dataPoints.length > 0);

            if (!isEmpty) {
                for (var i = 0; i < dataPoints.length; i++) {
                isEmpty = !dataPoints[i].y;
                if (!isEmpty)
                    break;
                }
            }

            if (!chart.options.subtitles)
                chart.options.subtitles = [];
            if (isEmpty) {
                chart.options.subtitles.push({
                text: text,
                verticalAlign: 'center',
                });
                chart.options.data[0].showInLegend = false;
            } else {
                chart.options.data[0].showInLegend = true;
            }
        },
        async showDetail(e){
            this.$store.dispatch('setOverlay', true)

            var reqBody = {
                'group_def': e.dataPoint.group_def,
                'periode': (new Date(new Date(e.dataPoint.x) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7).replace(/-/g,""),
                'mach_type': this.machine_type ? this.machine_type : '',
                'mach_id': this.machine ? this.machine : '',
                'brand_id': this.brand ? this.brand : '',
                'coat_mass': this.coat_mass ? this.coat_mass : '',
                'grade_id': this.grade ? this.grade : '',
                'thick': this.thick ? this.thick : '',
                'width': this.width ? this.width : '',
                'color_id': this.colour ? this.colour : '',
                'quality_id': this.quality ? this.quality : ''
            }
            const respDataMachType = await backendApi.fetchCore('/v2/sr/analysis/defect/show/mach_type', reqBody, false, false, false)
            const respDataMachId = await backendApi.fetchCore('/v2/sr/analysis/defect/show/mach_id', reqBody, false, false, false)
            const respDataBrand = await backendApi.fetchCore('/v2/sr/analysis/defect/show/brand_id', reqBody, false, false, false)
            const respDataThick = await backendApi.fetchCore('/v2/sr/analysis/defect/show/thick', reqBody, false, false, false)
            const respDataWidth = await backendApi.fetchCore('/v2/sr/analysis/defect/show/width', reqBody, false, false, false)
            const respDataGrade = await backendApi.fetchCore('/v2/sr/analysis/defect/show/grade_id', reqBody, false, false, false)
            const respDataCoatMass = await backendApi.fetchCore('/v2/sr/analysis/defect/show/coat_mass', reqBody, false, false, false)
            const respDataQuality = await backendApi.fetchCore('/v2/sr/analysis/defect/show/quality_id', reqBody, false, false, false)
            const respDataColor = await backendApi.fetchCore('/v2/sr/analysis/defect/show/color_id', reqBody, false, false, false)

            if (respDataMachType.status === 200 && respDataMachId.status === 200 && respDataBrand.status === 200 && respDataThick.status === 200 && respDataWidth.status === 200 && respDataGrade.status === 200 && respDataCoatMass.status === 200 && respDataQuality.status === 200 && respDataColor.status === 200) {
                this.showDataDetail = false
                var param = (new Date(new Date(e.dataPoint.x) - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7).replace(/-/g,"");
                var label_detail = "Periode: "+param;

                for (var i = 0; i < respDataMachType.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataMachType.data.length; i++) {

                        dt_detail.push({  label: respDataMachType.data[i].mach_type,  y: parseFloat(respDataMachType.data[i].total_wgt), legendText: respDataMachType.data[i].mach_type });

                    }
                    console.log(dt_detail)
                    var container_detail = 'container2';
                    var title_detail = 'by Machine Type'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataMachId.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataMachId.data.length; i++) {

                        dt_detail.push({  label: respDataMachId.data[i].mach_id,  y: parseFloat(respDataMachId.data[i].total_wgt), legendText: respDataMachId.data[i].mach_id });

                    }
                    var container_detail = 'container3';
                    var title_detail = 'by Machine ID'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataBrand.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataBrand.data.length; i++) {

                        dt_detail.push({  label: respDataBrand.data[i].brand_id,  y: parseFloat(respDataBrand.data[i].total_wgt), legendText: respDataBrand.data[i].brand_id });

                    }
                    var container_detail = 'container4';
                    var title_detail = 'by Brand'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataThick.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataThick.data.length; i++) {

                        dt_detail.push({  label: respDataThick.data[i].thick,  y: parseFloat(respDataThick.data[i].total_wgt), legendText: respDataThick.data[i].thick });

                    }
                    var container_detail = 'container5';
                    var title_detail = 'by Thick'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataWidth.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataWidth.data.length; i++) {

                        dt_detail.push({  label: respDataWidth.data[i].width,  y: parseFloat(respDataWidth.data[i].total_wgt), legendText: respDataWidth.data[i].width });

                    }
                    var container_detail = 'container6';
                    var title_detail = 'by Width'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataGrade.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataGrade.data.length; i++) {

                        dt_detail.push({  label: respDataGrade.data[i].grade_id,  y: parseFloat(respDataGrade.data[i].total_wgt), legendText: respDataGrade.data[i].grade_id });

                    }
                    var container_detail = 'container7';
                    var title_detail = 'by Grade'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataGrade.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataGrade.data.length; i++) {

                        dt_detail.push({  label: respDataGrade.data[i].grade_id,  y: parseFloat(respDataGrade.data[i].total_wgt), legendText: respDataGrade.data[i].grade_id });

                    }
                    var container_detail = 'container7';
                    var title_detail = 'by Grade'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataCoatMass.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataCoatMass.data.length; i++) {

                        dt_detail.push({  label: respDataCoatMass.data[i].coat_mass,  y: parseFloat(respDataCoatMass.data[i].total_wgt), legendText: respDataCoatMass.data[i].coat_mass });

                    }
                    var container_detail = 'container8';
                    var title_detail = 'by Coat Mass'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }
                
                for (var i = 0; i < respDataQuality.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataQuality.data.length; i++) {

                        dt_detail.push({  label: respDataQuality.data[i].quality_id,  y: parseFloat(respDataQuality.data[i].total_wgt), legendText: respDataQuality.data[i].quality_id });

                    }
                    var container_detail = 'container9';
                    var title_detail = 'by Quality'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                for (var i = 0; i < respDataColor.data.length; i++) {

                    var dt_detail =  [];

                    for (var i = 0; i < respDataColor.data.length; i++) {

                        dt_detail.push({  label: respDataColor.data[i].color_id,  y: parseFloat(respDataColor.data[i].total_wgt), legendText: respDataColor.data[i].color_id });

                    }
                    var container_detail = 'container10';
                    var title_detail = 'by Color'
                    this.getPieChart(dt_detail, container_detail, title_detail, label_detail)

                }

                this.$store.dispatch('setOverlay', false)
            }
        },
        async exportExcel(){

        },
        startDownload(){

        },
        finishDownload(){

        },
        async getDetailDt(defect, index){
            this.items = []
            this.dt_defect = defect
            this.$store.dispatch('setOverlay', true)    

            for (let i = 0; i < this.dt_defects.length; i++) {
                const element = this.dt_defects[i];

                if (i == index) {
                    element.check = true
                } else {
                    element.check = false
                }
            }

            var reqBody = {
                'group_def': defect.group_def.trim(),
                'periode': defect.periode
            }
            const respData = await backendApi.fetchCore('/v2/sr/analysis/defect', reqBody, false, false, false)

            if (respData.status === 200) {
                this.items = respData.data
                this.$store.dispatch('setOverlay', false)    
            } else {
                this.$store.dispatch('setOverlay', false)    
            }
        }

    },
}
</script>